import React, { useState, useEffect, useRef, useContext } from "react";

import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../providers/UserProvider";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from '@mui/material/Stack';
import { TWS } from "../services/WebServices";
import HistoricItem from "../components/HistoricItem";
import HistoricPrepaidItem from "../components/HistoricPrepaidItem";
import Button from "@mui/material/Button";
import SendLoading from "../components/flexy/SendLoading";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import AddDebt from "../components/flexy/AddDebt"


const Transactions = props => {
  const { t } = useTranslation();
  const [list, setlist] = useState([]);
  const [idoomlist, setidoomlist] = useState([]);
  const [prepaidlist, setprepaidlist] = useState([]);
  const [mixedList, setmixedList] = useState([]);
  const [showmorebtn, setshowmorebtn] = useState(true);
  const [loading, setloading] = useState(true);

  const [isLoadingmore, setLoadingmore] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [isLoadingmoreidoom, setLoadingmoreidoom] = useState(false);
  const [isDisabledidoom, setisDisabledidoom] = useState(false);

  const [skipflexy, setskipflexy] = useState(20);
  const [skipidoom, setskipidoom] = useState(20);
  const { user } = useContext(UserContext);
  const [value, setValue] = React.useState("1");
  const [showmorevissible, setshowmorevissible] = useState(true);
  const [searchDisabled, setsearchDisabled] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    TWS.getTransactions(user.id, user.token)
      .then(res => {
        
        setlist(res.trans);
        setidoomlist(res.idoom);
        setprepaidlist(res.prepaid);
        handalmixerList(res.trans,res.idoom);
        setloading(false);
      })
      .catch(() => {
        TWS.onUnauthorized();
      });
  }, []);

  const handelDateSelection = (date, type) => {
    setloading(true);
    if (type == "filter") {
      TWS.getTransactionsFilter(date, user.id, user.token)
        .then(res => {
          setlist(res.daytransaction);
          setidoomlist(res.daytransactionidoom);
          setloading(false);
          setshowmorebtn(false);
        })
        .catch(() => {
          TWS.onUnauthorized();
        });
    } else {
      setshowmorebtn(true);
      TWS.getTransactions(user.id, user.token)
        .then(res => {
          setlist(res.trans);
          setidoomlist(res.idoom);
          setloading(false);
        })
        .catch(() => {
          TWS.onUnauthorized();
        });
    }
  };

  function loadMore(type) {
    var skip = 10;
    if (type == "flexy") {
      setLoadingmore(true);
      setisDisabled(true);
      skip = skipflexy;
    } else {
      setLoadingmoreidoom(true);
      setisDisabledidoom(true);
      skip = skipidoom;
    }

    TWS.getTransactionsPaginated(user.id, skip, type, user.token)
      .then(res => {
        if (type == "flexy") {
          res.data.forEach(element => {
            list.push(element);
          });
          setskipflexy(_old => _old + 10);
          setLoadingmore(false);
          setisDisabled(false);
        } else {
          res.data.forEach(element => {
            idoomlist.push(element);
          });
          setskipidoom(_old => _old + 10);
          setLoadingmoreidoom(false);
          setisDisabledidoom(false);
        }
       
      })
      .catch(() => {
        TWS.onUnauthorized();
      });
  }


  const handalmixerList =(flexy,idoom)=>{
    const items = flexy.concat(idoom); 

    items.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.created_at) - new Date(a.created_at);
    });
 
    setmixedList(items);
  }

  const transSearch =(keyword, type)=>{
     setsearchDisabled(false)
     if (keyword.toString().length > 0 ) {
      setshowmorevissible(false)
      
     }else{
      setshowmorevissible(true)
      setsearchDisabled(true)
     }
     
     TWS.transSearch(user.id, keyword,user.token)
      .then(res => {
       setlist(res);
      setsearchDisabled(true)
      });
  }


/**
 * debts
 */
const [isOpenDebt, setisOpenDebt] = useState(false);

const [debtPhone, setdebtPhone] = useState(null);
const [debtName, setdebtName] = useState('');
const [debtAmount, setdebtAmount] = useState(0);
const [operator, setoperator] = useState(0);


const handelDebtModal =(data)=>{
  setisOpenDebt(!isOpenDebt)
  setdebtPhone(data.phone)
  setdebtAmount(data.amount)
  let opr = data.network == 'Mobilis' ? 1 :  data.network == 'Djezzy' ? 2 : 3
   
    setoperator(opr)
  }

  const handelDebtModalClose =()=>{
    setisOpenDebt(false)
    setdebtPhone(null)
    setdebtName('')
    setdebtAmount(0)
    }





  return (
    <div className="wrapper">
      <Navbar active="transactions" props={props} data={user} />
      {/* main */}
      <div className="main-content pb-5 transactions-page">
        <div className="container">
          <h1 className="pagetitle_intern text-center">{t("transactions")}</h1>
          {/* Tab */}
          <SendLoading loading={loading} />
          <SendLoading loading={isLoadingmore} />
          <SendLoading loading={isLoadingmoreidoom} />
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Flexy & Cartes" value="4" />
                  <Tab label={t("flexy")} value="1" />
                  <Tab label={t("Cartes")} value="2" />
                  <Tab label="Tickets" value="3" />
                   
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="row justify-content-center">
                  <div className="row ">
                    <div className="col-8 col-sm-4 my-2 px-0">
                     <TextField
                          size="small"
                          id="outlined-number"
                          label="Rechercher"
                          type="text"
                          sx={{width:'100%', background:'#fff'}}
                          InputLabelProps={{
                            shrink: true,
                            
                          }}
                          placeholder="Ex:0698683132"
                          onChange={event => {
                            transSearch(event.target.value, 'flexy');
                          }}
                        />
                        </div>
                    </div>
                    
                     {!searchDisabled &&(  <Stack  justifyContent="center" alignItems="center" direction="row" sx={{marginTop:5, marginBottom:5}}>
                         <CircularProgress sx={{ color:'#A343FA' }}/>
                     </Stack>)}
                  {list.map((item, index) => {
                    return (
                      <HistoricItem key={index} data={item} type={"flexy"} handelDebtModal={handelDebtModal}/>
                    );
                  })}
                 {showmorevissible && ( <Button
                    className="loadmore_btn"
                    variant="contained"
                    onClick={() => {
                      loadMore("flexy");
                    }}
                  >
                    Afficher plus
                  </Button>)}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="row justify-content-center">
                  {idoomlist.map((item, index) => {
                    return (
                      <HistoricItem key={index} data={item} type={"idoom"} handelDebtModal={handelDebtModal}/>
                    );
                  })}
                  <Button
                    className="loadmore_btn"
                    variant="contained"
                    onClick={() => {
                      loadMore("idoom");
                    }}
                  >
                    Afficher plus
                  </Button>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="row justify-content-center">
                  {prepaidlist.map((item, index) => {
                    return (
                      <HistoricPrepaidItem
                        key={index}
                        data={item}
                        type={"flexy"}
                      />
                    );
                  })}
                  
                </div>
              </TabPanel>
               <TabPanel value="4">
                <div className="row justify-content-center">
                  {mixedList.map((item, index) => {
                    return (
                      <HistoricItem key={index} data={item} type={item.phone == null ? "idoom":"flexy"} handelDebtModal={handelDebtModal}/>
                    );
                  })}
                  
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <AddDebt user={user} isOpen={isOpenDebt} handelDebtModal={handelDebtModalClose} phone={debtPhone} customer={debtName} amount={debtAmount} operator={operator} />
    </div>
  );
};

export default Transactions;
