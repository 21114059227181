import React, { useState, useEffect, useRef, useContext } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


export default function TransactionMsg({
    isOpenTranStatus,handelTranstatModal, msg
}) {

  return (
    <Dialog
      open={isOpenTranStatus}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={true}
    >
     
      
      <DialogContent>
      <center><img src={msg != null ? "/images/success.png":"/images/alert.png"} width="50" /></center>
      <center>{msg != null ? msg : "Veuillez vérifier l'opération"}</center>
      </DialogContent>
      <DialogActions>
        <Button
          className="annuler_btn"
          onClick={() => {
            handelTranstatModal();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
