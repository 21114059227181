import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import OperatorSelectionButton from "../components/OperatorSelectionButton";
import { UserContext } from "../providers/UserProvider";
import Statistics from "../components/Statistics";
import { TWS } from "../services/WebServices";
import { db } from "../services/Firestore";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import SwitchAccount from "../components/SwitchAccount";

import {
  collection,
  query,
  where,
  setDoc,
  doc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import SoldeStatus from "../components/SoldeStatus";
import ErrorDialog from "../components/ErrorDialog";
import Notifications from "../components/Notifications";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import CryptoJS from 'crypto-js';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import Link from "@mui/material/Link";
import { useLocation } from 'react-router-dom';


export default function Home(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, updateUserInfos, stats,setDeviceId ,ooredoostatus,
    mobilisstatus,
    djezzystatus} = useContext(UserContext);
  const [errordialog, seterrordialog] = useState(false);
  const [notificationsmsg, setnotificationsmsg] = useState('');
  const [notificationstatus, setnotificationstatus] = useState(false);
  const [notificationstype, setnotificationstype] = useState('');
     const tawkMessengerRef = useRef();
 const notif_id = Date.now().toString();
 const [notifId, setnotifId] = useState(null);
 const [start_listen, setstart_listen] = useState(false);

  /**
   * get device id
   */
  const asyncLocalStorage = {
     setItem: function(key, value) {
      return Promise.resolve().then(function() {
        localStorage.setItem(key, value);
      });
    },
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        updateStatus(JSON.parse(deviceId));
        setDeviceId(JSON.parse(deviceId));
      }
    });

  }, []);
  useEffect(() => {
    asyncLocalStorage.getItem("notifid").then(id => {
      if (id == null) {
        asyncLocalStorage.setItem("notifid", JSON.stringify(notif_id));
        updateNotifStatus(notif_id)
        setnotifId(notif_id)
        setstart_listen(true)
      }else{
        setnotifId(JSON.parse(id))
        setstart_listen(true)
      }
    });
  }, []);
  /**
   * update response status to avoid errors
   */

  const updateStatus = async deviceId => {
    const docref = doc(db, "responses", deviceId);

    await updateDoc(docref, {
      status: "false"
    }).then(() => {});
  };

  const handelErrorDialog = value => {
    seterrordialog(value);
  };

  /**
   * keyboard key listing
   */
  useEffect(() => {
    window.addEventListener("keydown", detectKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", detectKeyDown);
    };
  }, []);
  const detectKeyDown = e => {
    if (e.key == 1) {
      navigate("flexy/1/true");
    }
    if (e.key == 2) {
      navigate("flexy/3/true");
    }
    if (e.key == 3) {
      navigate("flexy/2/true");
    }
    if (e.key == 4) {
      navigate("/djezzyfacture/2/true");
    }
    if (e.key == 5) {
      navigate("/idoom");
    }
    if (e.key == 6) {
      navigate("/idoomfourg");
    }
  };



/**
 * NOTIFICATIONS
 */

useEffect(() => {
 
  setnotificationstatus(false)
    onSnapshot(doc(db, "notifications", 'msgnotifications'), doc => {
       setnotificationstype(doc.data().type)
        setnotificationsmsg(doc.data().msg)
        setnotificationstatus(doc.data().status) 
    });

}, []);

  /**
   * listen to notifications 
   */
  useEffect(() => {
    // 12748NFNFLZLL0O03O4SLSK
 if (start_listen) {
 
      onSnapshot(doc(db, "usersnotifications", notifId), doc => {
     
        if (doc.data().status == true && doc.data().client_id == user.id) {
          
          setnotifMesg(doc.data().message)
          setopenNotif(true);
          updateNotifStatus(notifId)
        }
      });
 }
  }, [start_listen]);

const [openNotif, setopenNotif] = React.useState(false);
const [notifMesg, setnotifMesg] = React.useState('');

 const handleCloseNotif = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setopenNotif(false);
  };

  const updateNotifStatus = async id => {
    const docref = doc(db, "usersnotifications", id);

    await setDoc(docref, {status: false,client_id:user.id},{merge: true }).then(() => {});
  };

/**
 *  DISCUSSION 
 */


    const onLoad = () => {
      
        //  setting name and email

        tawkMessengerRef.current.setAttributes({
          hash : HMAC( user.id,'5333b5ee8387fa1b2725e372443ff5f1ea8eb703'),    // required
          userId : user.id, 
            name : user.name,
            phone : user.phone,
          
        }, function(error) {
            // do something if error
        });
    };

async function HMAC(userid, apikey){

const hash = CryptoJS.HmacSHA256(userid, apikey)
  .toString(CryptoJS.enc.Hex)
 return hash;
}


// switch account
const [switchModalOpen, setswitchModalOpen] = useState(false);
 useEffect(() => {
    asyncLocalStorage.getItem("ispro").then(resp => {
      if (resp == null) {
        setswitchModalOpen(true)
      }
    });
  }, []);
const handelUserChoise = (choise) =>{
  asyncLocalStorage.setItem("ispro", JSON.stringify(choise));
  if (choise == 'pro') {
    setswitchModalOpen(false)
    navigate("rechargements");
  }else{
    setswitchModalOpen(false)
  }
    
}
/**
 * SLIDESHOW
 */
const URL ='https://mdash.rminov.com/'
const [slideProducts, setslideProducts] = useState([]);
const [slideNumber, setslideNumber] = useState(1);
const [slideNumberScroll, setslideNumberScroll] = useState(1);
const responsiveSettings = [

  {
        breakpoint: 1024,
        settings: {
            slidesToShow: slideNumber,
            slidesToScroll: slideNumberScroll
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: slideNumber - 2,
            slidesToScroll: slideNumberScroll 
        }
    },
    {
        breakpoint: 200,
        settings: {
           slidesToShow: slideNumber - 3,
            slidesToScroll: slideNumberScroll -1 
        }
    }
];
useEffect(() => {

    TWS.slideshow(user.token)
      .then(res => {
        setslideProducts(res)
        setslideNumber(4)
        setslideNumberScroll(2)
      })
      .catch(() => {});

      checkdeal()

  }, []);

/**
 * check offers banner
 */
const [deal, setDeal] = useState([]);
const [dealstatus, setdealstatus] = useState(false);
function checkdeal(){
 
  TWS.checkDeals(user.token)
    .then(res => {
      setDeal(res.dealBanner)
      setdealstatus(res.dealstatus)
     
    })
    .catch(() => {
      //TWS.onUnauthorized();
    });
}

  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />
      {/* main */}
      <div className="main-content pb-5 text-center">
        {/*  <div className="container d-flex justify-content-between event">
          <img src="/images/bgl.png"/>
          <img src="/images/bgr.png"/>
        </div>*/}

         
       
        {user.activebalance[0].balance <= 5000 ? <SoldeStatus /> : null}
       {notificationstatus && <Notifications type={notificationstype} icon={'home'} msg={notificationsmsg} />}
        <ErrorDialog errordialog={errordialog}
            handelerrordialog={handelErrorDialog} message="النظام تحت الصيانة يرجى الانتظار"/>
        
        <Snackbar sx={{width:'40%', }} anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={openNotif} autoHideDuration={10000} onClose={handleCloseNotif}>
            <Alert onClose={handleCloseNotif} severity="success" sx={{ width: '100%' }}>
           
              {notifMesg}
            </Alert>
        </Snackbar>

        {user.category == 1 ? (<SwitchAccount switchModalOpen={switchModalOpen} handelUserChoise={handelUserChoise}/>) : null}
        {dealstatus && <div className="container flexy-wrapper deals">
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-fill bd-highlight align-self-center">
            <h5 >  {deal?.title}</h5>
            <p >{deal?.subtitle}</p>
            <a href="/storeoffers">اطلب الان</a>
          </div>
          <div className="p-2 flex-fill bd-highlight">
          {deal?.linkimg != null  && <img src={deal?.linkimg} />}
          </div>
          
        </div>
        </div>}
        <Statistics
          balance={user.activebalance[0].balance}
          sent={stats}
          pts={user.activebalance[0].pts}
        />
        
        <div className="container flexy-wrapper">
          <h1 className="pagetitle text-center">Découvrez nos offres exclusives</h1>
           <Slide slidesToScroll={slideNumberScroll} slidesToShow={slideNumber} indicators={true} responsive={responsiveSettings} cssClass='homeSlideShow'>
                  {slideProducts.map((item, index) => {
                    
                      return(
                        <Link href={"/store"} key={index}>
                          <div className="each-slide-effect" >
                              
                              <img className="slide-element-img"  src={URL+item.images}/>
                              <div className="slide-element">
                              <p> {item.name} </p>
                                <div>
                                  {item.offers == null ? (<span className="slide-price">{item.price} DA</span>):
                                   (<div>
                                    <span className="slide-price">{item.offers} DA</span>
                                    <span className="slide-price-offer">{item.price} DA</span>
                                    </div>
                                    
                                  )}
                                </div>
                              </div>
                          </div>
                          </Link>
                      )
                      })}

        </Slide>
          </div>
        <div className="container flexy-wrapper">
          <h1 className="pagetitle text-center">Flexy Mobile</h1>

          <div className="cards row justify-content-center">
            <OperatorSelectionButton
              path={mobilisstatus ? "/flexy/1/true" : null}
              imgPath={"images/mobilis_logo.png"}
              imgClass={"ops_img_mobilis"}
              keydown={"images/key1.png"}
              title={null}
              status={mobilisstatus}
            />
            <OperatorSelectionButton
              path={ooredoostatus? "/flexy/3/true":null}
              imgPath={"images/ooredoo_logo.png"}
              imgClass={"ops_img"}
              keydown={"images/key2.png"}
              title={null}
              status={ooredoostatus}
            />
          </div>
          <div className="cards row justify-content-center">
            <OperatorSelectionButton
              path={djezzystatus ? "/flexy/2/true" :null}
              imgPath={"images/djezzy_logo.png"}
              imgClass={"ops_img"}
              keydown={"images/key3.png"}
              title={null}
              status={djezzystatus}
            />
            <OperatorSelectionButton
              path="/djezzyfacture/2/true"
              imgPath={"images/djezzy_logo_facture.png"}
              imgClass={"ops_img_fatcure"}
              keydown={"images/key4.png"}
              title={null}
              status={djezzystatus}
            />
          </div>
        </div>
        <div className="container  recharge-internet-wrapper">
          <h1 className="pagetitle text-center mb-100">
            Rechargement Internet
          </h1>
          <div className="cards row justify-content-center">
            <OperatorSelectionButton
              path="/idoom"
              imgPath={"images/idoom_adsl.png"}
              imgClass={"ops_img_inter"}
              keydown={"images/key5.png"}
              title={null}
              status={true}
             
            />
            <OperatorSelectionButton
              path="/idoomfourg"
              imgPath={"images/idoom_4glte.png"}
              imgClass={"ops_img_inter"}
              keydown={"images/key6.png"}
              title={null}
              status={true}
            />
          </div>
        </div>
       <div className="container  recharge-internet-wrapper">
          <h1 className="pagetitle text-center mb-100">
            Cartes et accessoires
          </h1>
          <div className="cards row justify-content-center">
            <OperatorSelectionButton
              path="/prepaidcards/1"
              imgPath={"images/cardmob.jpg"}
              imgClass={"card_img_inter"}
              keydown={null}
              //title={"Cartes Mobilis"}
              title={null}
              status={true}
            />
            <OperatorSelectionButton
              path="/store"
              imgPath={"images/store.jpg"}
              imgClass={"card_img_inter"}
              keydown={null}
              //title={"Cartes Mobilis"}
              title={null}
              status={true}
            />
            {/*   <OperatorSelectionButton
              path="/prepaidcards/3"
              imgPath={"images/ooredoo_logo.png"}
              imgClass={"ops_img_inter"}
              keydown={null}
              title={"Cartes Ooredoo"}
            />*/}
          </div>
          {/* <div className="cards row justify-content-center">
            <OperatorSelectionButton
              path="/prepaidcards/2"
              imgPath={"images/djezzy_logo.png"}
              imgClass={"ops_img_inter"}
              keydown={null}
              title={"Cartes Djezzy"}
            />
          </div>*/}
        </div>
        <div className="container  recharge-internet-wrapper">
          <h1 className="pagetitle text-center mb-100">
            Jeux Mobile
          </h1>
          <div className="cards row justify-content-center">
            <OperatorSelectionButton
              path="/gamescards/1"
              imgPath={"images/pubg.jpg"}
              imgClass={"card_img_inter"}
              keydown={null}
              //title={"Cartes Mobilis"}
              title={null}
              status={true}
            />
            <OperatorSelectionButton
              path="/gamescards/2"
              imgPath={"images/freefire.jpg"}
              imgClass={"card_img_inter"}
              keydown={null}
              //title={"Cartes Mobilis"}
              title={null}
              status={true}
            />
            </div>
          </div>
         <TawkMessengerReact
                 onLoad={onLoad}
                propertyId="6496242694cf5d49dc5f8423"
                widgetId="1h3l7qdle"
                ref={tawkMessengerRef}/>
      </div>
    </div>
  );
}
