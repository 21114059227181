const Colors = {
  BACKGROUND_SCREEN: '#fff',
  BACKGROUND_PRIMARY: '#8343f5',
  BACKGROUND_PRIMARY2: '#ffc44b',
  BACKGROUND_SECONDARY: '#f2f2f2',
  BACKGROUND_PRIMARY_DARK: '#7b34fb',
  BACKGROUND_PRIMARY_LIGHT: '#a475f7',

  PRIMARY: '#626262',
  SECONDARY: '#878787',
  CARD_HOMEPAGE: '#FAFAFA',
  CONTENT_PRIMARY_REVERSED: '#F2F2F2',

  DJEZZY: '#DB0019',
  MOBILIS: '#3DA929',
  OOREDDOO: '#E30613',
  IDOOM: '#2556a2',

  STOREPRIMARYLIGHT: '#f3ede6',
  STOREOFFERS: '#FF184F',
  STOREOFFERSDarker:'#E21545',
};

export default Colors;
