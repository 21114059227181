

const LOG_API_REQUESTS = false;

class Webservice {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
    this.token = null;

    this.onUnauthorized = () => {};
  }

  setToken(token) {
    this.token = token;
  }

  postLogin(email, password) {
    return this.fetch("POST", "/weblogin", { email, password });
  }

  getAuthenticatedUser() {
    return this.fetch("GET", "/web-userdata-simdata/" + this.token);
  }

  getUssdCodes(network, token) {
    return this.fetch("GET", "/web-ussd/" + network + "/" + token);
  }

  getTransactions(id, token) {
    return this.fetch("GET", `/web-transactions-list/${id}/${token}`);
  }
  getTransactionsPaginated(id, skip, type, token) {
    return this.fetch(
      "GET",
      `/web-transactions-list-paginated/${id}/${token}/${skip}/${type}`
    );
  }

  getSuggestions(id, network, keyword, token) {
    return this.fetch(
      "GET",
      `/web-search-phone/${token}/${id}/${network}?search=${keyword}`
    );
  }
  getPoints(network, token) {
    return this.fetch("GET", "/web-points/" + network + "/" + token);
  }

  checkCards(type, token) {
    return this.fetch("GET", "/web-idoom-check/" + token + "/" + type);
  }

  getCardNumber(client_id, amount, type, token) {
    return this.fetch(
      "GET",
      "/web-idoom/" + client_id + "/" + token + "/" + amount + "/" + type
    );
  }

  getRechargments(id, token) {
    return this.fetch("GET", `/web-recharge-historic/${token}/${id}`);
  }

  getSucessMessages(token) {
    return this.fetch("GET", "/web-sucess-msg/" + token);
  }

  getTransactionsFilter(day, id, token) {
    return this.fetch(
      "GET",
      "/web-transaction-filter/" + token + "/" + day + "/" + id
    );
  }

  getGifts(token) {
    return this.fetch("GET", "/web-gift/" + token);
  }

  checkPrepaidCards(type,token) {
    return this.fetch('GET', '/web-prepaid-check/' + token + '/' + type);
  }

  getPrepaidCardNumber(client_id, amount, type,token) {
    return this.fetch(
      'GET',
      '/web-prepaid/' +
        client_id +
        '/' +
        token +
        '/' +
        amount +
        '/' +
        type,
    );
  }

  postUserPassword(password,oldpassword,token) {
    return this.fetch("POST", "/web-userpass/update/"+token, { password,oldpassword },true);
  }


   transSearch(id,keyword,token) {
     return this.fetch("GET", "/web-transsearch/"+id+"/"+token+"?search="+keyword);
     
    }

  postCustomer(customer,phone,client_id, token) {
    return this.fetch("POST", "/web-customers/store/"+token, { customer,phone,client_id },true);
  }

  getSuggestionsWithName(id, keyword, token) {
    return this.fetch(
      "GET",
      "/web-customers/search/"+id+"/"+token+"?search="+keyword
    );
  }

   getCustomersList(id, token) {
    return this.fetch('GET', '/web-customers/list/' + id + '/' + token);
  }

  getCustomerWithNameOrPhone(id, keyword, token) {
    return this.fetch(
      'GET',
      '/web-customerslist/search/' + id + '/' + token + '?search=' + keyword,
    );
  }

  deleteCustomer(id, token) {
    return this.fetch('POST', '/web-customers/delete/' + id + '/' + token);
  }


  addClientProAccount(token, responsible,name, phone, password, wilaya, region) {
    return this.fetch('POST', '/web-proaccount/store/' + token,{responsible,name, phone, password, wilaya, region },true);
  }
  
  getClientsProAccount(id, token) {
    return this.fetch('GET', '/web-proaccount/lists/' + id + '/' + token);
  }

  addBalanceProAccount(responsible, token,balance,client_id) {
    return this.fetch('POST', '/web-proaccount/addbalance/' + responsible + '/' + token,{responsible,balance,client_id});
  }
  rechargeListProAccount(responsible, token) {
    return this.fetch('GET', '/web-proaccount/recharge/' + responsible +'/'+ token);
  }
  
   SearchByRegion(id, keyword, token) {
    return this.fetch(
      'GET',
      '/web-proaccount/searchregion/' + id + '/' + token + '?search=' + keyword,
    );
  }

  SearchByClient(id, keyword, token) {
    return this.fetch(
      'GET',
      '/web-proaccount/searchbyclient/' + id + '/' + token + '?search=' + keyword,
    );
  }

  StoreProductList(token) {
    return this.fetch('GET', '/web-store/productlist/' +token);
  }

  productSearch(keyword, token) {
    return this.fetch(
      'GET',
      '/app-store/searchproducts/' + token + '?search=' + keyword,
    );
  }

  getVideos() {
    return this.fetch('GET', '/web-videos');
  }

   getLateClients(responsible, token) {
    return this.fetch('GET', '/web-proaccount/late/' + responsible +'/'+ token);
  }
  postClientPyament(responsible_id,amount,client_id, token) {
    return this.fetch("POST", "/web-proaccount/sendpayment/"+token, { responsible_id,amount,client_id },true);
  }

  getPaymentsList(responsible, token) {
    return this.fetch('GET', '/web-proaccount/payments/' + responsible +'/'+ token);
  }

  getPaymentsListByAdmin(responsible, token) {
    return this.fetch('GET', '/web-proaccount/paymentsbyadmin/' + responsible +'/'+ token);
  }
  
  getstatistics (responsible, token){
        return this.fetch('GET', '/web-proaccount/statistics_transactions/' + responsible +'/'+ token);
  }

  getlastTranas (client_id, token){
        return this.fetch('GET', '/web-protectionnew/' + client_id +'/'+ token);
  }

 pendingClient(responsible,client_id, token){
        return this.fetch('GET', '/web-proaccount/clientpending/' +responsible +'/'+ client_id +'/'+ token);
  }
  
 sendMessageToAdmin(responsible,client_id, token,msg){
        return this.fetch('GET', '/web-proaccount/setmessagetoadmin/' +responsible +'/'+ client_id +'/'+ token+'/'+msg);
  }

  getMessageofCleint(responsible,client_id, token){
        return this.fetch('GET', '/web-proaccount/getmessageofclient/' +responsible +'/'+ client_id +'/'+ token);
  }
  getStatsResp(responsible, token){
    return this.fetch('GET', '/web-proaccount/stats/'+responsible +'/'+ token);
}


  slideshow(token){
    
      return this.fetch('GET', '/web-store/slideshow/'+ token);

  }

  postCartPoroducts(token,client_id,amount, comment,products) {
    return this.fetch("POST", "/web-store/storeorder/"+token, { client_id,amount,comment,products },true);
  }

  checkGameCards(type,token) {
    return this.fetch('GET', '/web-games-check/' + token + '/' + type);
  }


  getGameCardNumber(client_id, amount, type,token) {
    return this.fetch(
      'GET',
      '/web-gamecard-purchase/' +
        client_id +
        '/' +
        token +
        '/' +
        amount +
        '/' +
        type,
    );
  }

  postDebts(token,client_id,name,amount, phone,type,comment) {
    return this.fetch("POST", "/client-debts/"+token, { client_id,name,amount,phone,type,comment },true);
  }

  getDebts(id,token) {
    return this.fetch('GET', '/client-debts/' + id + '/' + token);
  }

  deleteDebts(id,token,item_id) {
    return this.fetch('GET', '/client-debts/destory/' + id + '/' + token+'/'+item_id);
  }

  checkFingerPrint(id,token,fingerprint) {
    return this.fetch('GET', '/fingerprints/store/' + id + '/' + token+'/'+fingerprint);
  }

  checkpin(id,token,pin,fingerprint) {
    return this.fetch('GET', '/fingerprints/check/' + id + '/' + token+'/'+pin+'/'+fingerprint);
  }

  getPin(id,token) {
    return this.fetch('GET', '/fingerprints/retreive/' + id + '/' + token);
  }

  getTransactionsSim(id,token){
    
    return this.fetch('GET', '/simtransactionsproaccount/' + id + '/' + token);
  }

  getStoreCategories(token){
    
    return this.fetch('GET', '/app-store/categories/'+ token);
  }
  getProductsByCategories(id,token){
    
    return this.fetch('GET', '/app-store/productsbycategories/'+ id + '/' + token);
  }
  getStoreProductsOffers(token) {
    return this.fetch(
      'GET',
      `/app-store/productlistoffers/${token}`,
    );
  }

  checkDeals(token) {
    return this.fetch(
      'GET',
      `/app-store/dealbanner/${token}`,
    );
  }
 

  fetch(method, endpoint, params = {}, disableErrors = false) {
    let url = `${this.apiUrl}${endpoint}`;
    if (method.toLowerCase() === "get") {
      if (Object.keys(params).length) {
        url += "?" + new URLSearchParams(params).toString();
      }
    }

    if (LOG_API_REQUESTS) {
      if (method.toLowerCase() === "get")
        console.log(method.toUpperCase() + `: ${url}`);
      else console.log(method.toUpperCase() + `: ${url}`, params);
    }

    return new Promise((resolve, reject) => {
      fetch(url, {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
       
          Authorization: "Bearer " + this.token
        },
        body:
          method.toLowerCase() === "post" ? JSON.stringify(params) : undefined
      })
        .then(res => {
          switch (res.status) {
            case 200:
            case 201:
              resolve(res.json());
              break;
            case 422:
              res.json().then(json => reject(json.errors));
              break;
            case 401:
              res.json().then(json => reject(json.errors));
              if (!disableErrors) this.onUnauthorized();

              break;
            case 500:
              if (!disableErrors)
                alert(
                  "Une erreur est survenue, merci de réessayer ulterieurement (1)"
                );

              break;
            default:
          }
        })
        .catch(err => {
          if (!disableErrors)
            alert(
              "Une erreur est survenue, merci de vérifier votre connection internet ou de réessayer ulterieurement"
            );
          reject();
        });
    });
  }
}

export const TWS = new Webservice("https://mdash.rminov.com/api");
